<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="580"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 4 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 20 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 分类名称 -->
          <a-form-item label="分类名称:">
            <div>
              {{ data.categoryName }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 备注 -->
          <a-form-item label="备注:">
            <div>
              {{ data.comments }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 状态 -->
          <a-form-item label="是否显示:">
            <div>
              {{ data.isShow }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 状态 -->
          <a-form-item label="排序方式:">
            <div>
              {{ data.sortNumber }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'categoryIndexDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {}
  },
  watch: {
    data() {
      if (this.data) {
        // console.log('data111', this.data)
        // this.data.map(item => {
        //   if (item.isShow === 0) {
        //     item.isShowName = '不显示'
        //   } else if (item.isShow === 1) {
        //     item.isShowName = '显示'
        //   }
        // })
      }
    }
  },
  mounted() {
    // this.init()
  },
  methods: {
    init() {
      //   if (this.data.questionType === 0) {
      //     this.data.map((item) => {
      //       item.questionTypeName = '单选'
      //     })
      //   } else if (this.data.questionType === 1) {
      //     this.data.map((item) => {
      //       item.questionTypeName = '多选'
      //     })
      //   }
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
.answer {
  padding-left: 5%;
}
</style>
